* {
  box-sizing: border-box;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clearfix::before,
.clearfix::after {
  display: table;
  content: "";
}

.clearfix::after {
  clear: both;
}

a[disabled] {
  pointer-events: none;
}

[class*="prevent-scroll"] {
  height: 100vh;
  overflow: hidden;
}

[hidden] {
  display: none !important;
}

.right {
  float: right;
}

.left {
  float: left;
}

.m-bottom-12 {
  margin-bottom: 12px;
}

// 常用margin
.app {
  .margin-0 {
    margin-bottom: 0;
  }

  .margin-20 {
    margin: 20px;
  }

  .margin-30 {
    margin: 30px;
  }

  .m-bottom-12 {
    margin-bottom: 12px;
  }

  .m-bottom-20 {
    margin-bottom: 20px;
  }

  .m-bottom-30 {
    margin-bottom: 30px;
  }

  // 常用padding
  .padding-20 {
    padding: 20px;
  }

  .padding-30 {
    padding: 30px;
  }

  // marginLeft
  .m-left-20 {
    margin-left: 20px;
  }

  // 文本居中
  .text-center {
    text-align: center;
  }

  // 整体页面布局
  .page-content {
    padding: 24px;
    background: #fff;
  }

  // 模块布局
  .card-content {
    padding: 24px;
    margin-bottom: 30px;
    background: #fff;
  }

  .bg-blue {
    color: #fff;
    background: #557ff5;
  }

  .bg-orange {
    color: #fff;
    background: #fd8757;
  }

  .bg-red {
    color: #fff;
    background: #e2665f;
  }

  .bg-light {
    background: rgb(244, 247, 254);
  }

  .bg-o-light {
    background: rgb(255, 248, 245);
  }

  .bg-r-light {
    background: rgb(252, 240, 240);
  }
}

// 必填*号样式
.required-tag {
  display: inline-block;
  margin-right: 4px;
  font-family: SimSun, sans-serif;
  font-size: 14px;
  line-height: 1;
  color: #ff4d4f;
}

.color-6 {
  color: #666;
}

.color-danger {
  color: #ff4d4f;
}

// 详情key-value展示
.detail-label {
  width: 120px;
  font-size: 14px;
  line-height: 2;
  text-align: right;
}

.detail-value {
  font-size: 14px;
  line-height: 2;
}

// // handsontable
// #hot-display-license-info {
//   display: none !important;
// }
