.app {
  .ant-form-inline .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-picker {
    width: 100%;
  }

  // InputNumber
  .ant-input-number-group-wrapper {
    width: 100%;
  }

  // .ant-input {
  //   max-width: 800px;
  // }

  .ant-breadcrumb > span:last-child a {
    cursor: default;
  }

  .ant-breadcrumb {
    margin: 12px 16px;
  }
}

// .ant-select {
//   min-width: 183px;
// }
